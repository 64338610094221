import React, { useContext, useEffect, useState } from "react";
import "./detail.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getApi } from "../../../api/customApi";
import Spinner from "../../../components/Spinner";
import { LangContext } from "../../../context/LangContext";
import { t } from "i18next";
import { ServiceCategory } from "../../../components/Services/Category";

const ServiceDetail = () => {
  const { lang } = useContext(LangContext);
  const { slug } = useParams();
  const navigate = useNavigate();

  const { data: categories, isLoading: categoriesLoading } = useQuery({
    queryKey: ["services-categories", lang],
    queryFn: () => getApi("/service-categories"),
  });

  const { data: serviceData, isLoading: serviceDataLoading } = useQuery({
    queryKey: ["service-detail", slug, lang],
    queryFn: () => getApi(`/services/${slug}`),
  });

  useEffect(() => {
    const contentContainer = document.querySelector(
      ".service_detail_main_content_element"
    );

    if (serviceData) {
      const content = serviceData?.[0]?.content?.[lang] || "";
      if (contentContainer) {
        contentContainer.innerHTML = "";
        const contentElement = document.createElement("p");
        contentElement.classList.add("detail_content");
        contentElement.innerHTML = content;
        contentContainer.appendChild(contentElement);
      }
    }
  }, [serviceData, lang]);

  const updateServiceName = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="service_detail">
      <div className="service_detail_head">
        <h3>{serviceData?.[0]?.title?.[lang]}</h3>
      </div>
      <div className="service_detail_main">
        <div className="service_detail_main_content">
          {serviceDataLoading ? (
            <Spinner />
          ) : (
            <div className="service_detail_main_content_element"></div>
          )}
        </div>
        <div className="service_detail_main_right">
          <div className="service_detail_main_right_categories">
            <h3 className="service_detail_main_right_categories_head">
              {t("services.categories")}
            </h3>
            <div className="service_detail_main_right_categories_container">
              {categoriesLoading && <Spinner />}
              {categories &&
                categories.map((category) => (
                  <ServiceCategory
                    key={category.id}
                    name={category.title?.[lang]}
                    id={category.id}
                    updateService={updateServiceName}
                  />
                ))}
            </div>
          </div>
          <button
            onClick={() => navigate("/contact")}
            className="service_detail_contact_button"
          >
            {t("services.contactBtn")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetail;
